import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import ReturnAppDialog from '../Dialogs/ReturnApplicationDialog';
import * as Ant from 'antd';
import RefundDialog from '../Dialogs/RefundDialog';
import {navigate} from 'gatsby';
import {errorHandler} from '../errors';
import {useOutlet} from 'reconnect.js';
import {RETURN_STATUS} from '../dictionary';
import Block from '../Widgets/Block';
import Button from '../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import Anchor from '../Widgets/Anchor';

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function ReturnApplicationList(props) {
  const [records, setRecords] = useState([]);
  const [filters, setFilters] = useState({
    status: 'all',
    ...PAGINATION_INIT,
  });
  const [total, setTotal] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedRefund, setSelectedRefund] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRefundDialog, setOpenRefundDialog] = useState(false);
  const [actions] = useOutlet('actions');

  const getRecords = useCallback(async () => {
    let {status, current, pageSize} = filters;

    actions.setLoading(true);
    try {
      let resp = await actions.getReturnApps({
        ordering: '-created',
        offset: (current - 1) * pageSize,
        limit: pageSize,
        ...(status !== 'all' && {status: status}),
      });
      setRecords(resp.results);
      setTotal(resp.count);
    } catch (err) {
      errorHandler(err);
    }
    actions.setLoading(false);
  }, [actions, filters]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return (
    <Wrapper>
      <Block style={{marginBottom: 20}}>
        <div>
          <div className="row">
            <h4 className="short">處理方式</h4>
            <Ant.Select
              value={filters.status}
              onChange={(nextValue) => {
                setFilters((prev) => ({
                  ...prev,
                  status: nextValue,
                  ...PAGINATION_INIT,
                }));
              }}>
              {Object.keys(RETURN_STATUS)
                .filter((key) =>
                  [
                    'all',
                    'pending',
                    'returning',
                    'rejected',
                    'return_completed',
                  ].includes(key),
                )
                .map((key) => ({
                  label: RETURN_STATUS[key],
                  value: key,
                }))
                .map((option, idx) => (
                  <Ant.Select.Option key={idx} value={option.value}>
                    {option.label}
                  </Ant.Select.Option>
                ))}
            </Ant.Select>

            <div style={{flex: 1}} />
            <Button
              type="primary"
              onClick={getRecords}
              icon={
                <Refresh color="#fff" size={20} style={{marginRight: 5}} />
              }>
              刷新
            </Button>
          </div>
        </div>
      </Block>
      <Block>
        <h2>{RETURN_STATUS[filters.status]}</h2>
        <div>
          <Ant.Table
            columns={[
              {
                title: '售後單編號',
                render: (_, instance) => (
                  <Anchor
                    type="button"
                    onClick={() => {
                      setSelectedRecord(instance);
                      setOpenDialog(true);
                    }}>
                    # {instance.id}
                  </Anchor>
                ),
              },
              {
                title: '訂單編號',
                render: (_, instance) => (
                  <Anchor to={`/order/?id=${instance.order}`}>
                    {instance.display_id}
                  </Anchor>
                ),
              },
              {
                title: '處理方式',
                render: (_, record) => RETURN_STATUS[record.status],
              },
              {
                title: '建立時間',
                render: (_, order) =>
                  ` ${order.created.slice(0, 10)} ${order.created.slice(
                    11,
                    16,
                  )}`,
              },
              {
                title: '備註',
                dataIndex: 'note',
              },
            ]}
            dataSource={records}
            rowKey={(item) => item.id}
            pagination={{
              pageSize: filters.pageSize,
              current: filters.current,
              total,
            }}
            onChange={(pagination) =>
              setFilters((prev) => ({
                ...prev,
                pageSize: pagination.pageSize,
                current: pagination.current,
              }))
            }
          />
        </div>
      </Block>

      {openDialog && (
        <ReturnAppDialog
          returnApp={selectedRecord}
          onUpdate={getRecords}
          openRefundDialog={async () => {
            setOpenRefundDialog(true);
          }}
          closeDialog={() => setOpenDialog(false)}
        />
      )}

      {openRefundDialog && (
        <RefundDialog
          record={selectedRefund}
          returnApp={selectedRecord}
          closeDialog={() => {
            setOpenRefundDialog(false);
            setSelectedRefund(null);
          }}
          onUpdate={() => {
            Ant.message.success('請至退款階段查看新建立的退款單');
          }}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
